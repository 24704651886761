import React from "react"
import LayoutCourse from "../../../../../layouts/course"
import Seo from "../../../../../components/seo"
import Stack from "../../../../../components/stack"
import Box from "../../../../../components/box"
import CourseFooterNext from "../../../../../components/course-footer-next"
import {
  getCourseNavigation,
  useCourseStore,
  getCourseProgress,
} from "../../../../../store/courses"
import Note from "../../../../../components/note"
import Paragraph from "../../../../../components/paragraph"
import Text from "../../../../../components/text"
import Divider from "../../../../../components/divider"
import FeedbackHeaderBool from "../../../../../components/feedback-header-bool"
import EvaluationRow from "../../../../../components/evaluation-row"
import reisekosten from "../../../../../content/kurse/hoelderlins-reisen/01-friedrich-hoelderlin/reisekosten"

const taskProps = {
  courseId: "hoelderlins-reisen",
  chapterId: "01-friedrich-hoelderlin",
  taskId: "reisekosten",
}

const Page = () => {
  const { getTask, getAnswer } = useCourseStore()
  const navigation = getCourseNavigation(taskProps)
  const progress = getCourseProgress(taskProps)
  const kosten = reisekosten({ getAnswer, getTask })

  return (
    <LayoutCourse
      navigation={navigation}
      footer={
        <CourseFooterNext to="/kurse/hoelderlins-reisen/01-friedrich-hoelderlin/korrigieren" />
      }
      progress={progress}
    >
      <Seo title="Reisekosten berechnen" />
      <Box>
        <Stack>
          <FeedbackHeaderBool
            confirmed={kosten.allCorrect}
            title="Insgesamt hat Hölderlin 637 Kreuzer ausgegeben."
          />
          <Paragraph>Hier findest du die Auflösung:</Paragraph>
          <Stack space={6}>
            <Stack space={3}>
              <EvaluationRow
                title={<Text size={[2, 3, 4]}>Auf dem Weg nach Speier</Text>}
                isCorrect={kosten.answerAnreise === kosten.solutionAnreise}
                answer={`${kosten.answerAnreise} cr.`}
                solution={`${kosten.solutionAnreise} cr.`}
              />
              <Divider />
              <EvaluationRow
                title={<Text size={[2, 3, 4]}>In Speier</Text>}
                isCorrect={kosten.answerSpeier === kosten.solutionSpeier}
                answer={`${kosten.answerSpeier} cr.`}
                solution={`${kosten.solutionSpeier} cr.`}
              />
              <Divider />
              <EvaluationRow
                title={<Text size={[2, 3, 4]}>Auf dem Rückweg</Text>}
                isCorrect={kosten.answerRueckweg === kosten.solutionRueckweg}
                answer={`${kosten.answerRueckweg} cr.`}
                solution={`${kosten.solutionRueckweg} cr.`}
              />
            </Stack>
            <Divider size={4} />

            <EvaluationRow
              title={<Text size={[2, 3, 4]}>Insgesamt</Text>}
              isCorrect={kosten.answerTotal === kosten.solutionTotal}
              answer={`${kosten.answerTotal} cr.`}
              solution={`${kosten.solutionTotal} cr.`}
            />
          </Stack>

          <Note>
            1 Kreuzer war so viel wie 1,5 Cent. Hölderlin hat also auf seiner
            Reise etwa 10 € ausgegeben. Er ist tatsächlich sehr sparsam gewesen!
            Da wird sich seine Mutter gefreut haben.
          </Note>

          <Paragraph>
            Hölderlins Mutter wollte, dass ihr Sohn später einmal einen Beruf
            bekommt, der ihm ein gutes und sicheres Einkommen einbringt. Doch
            Hölderlin hatte andere Pläne. Er wollte Schriftsteller werden und
            das war zu seiner Zeit weder leicht, noch brachte es besonders viel
            Geld ein.
          </Paragraph>
        </Stack>
      </Box>
    </LayoutCourse>
  )
}

export default Page
