import React from "react"
import PropTypes from "prop-types"

import Column from "./column"
import Columns from "./columns"
import Icon from "./icon"
import Inline from "./inline"
import Stack from "./stack"
import Text from "./text"

const EvaluationRow = ({ title, isCorrect, answer, solution }) => {
  return (
    <Columns alignY="center" collapse={[true, false]}>
      <Column>{title}</Column>
      <Column width="120px">
        <Stack space="1" alignX={["left", "right"]}>
          <Inline alignY="center" space={1}>
            <Text sans bold color={isCorrect ? "positive" : "negative"}>
              {answer}
            </Text>
            <Icon
              size={4}
              icon={isCorrect ? "done" : "close"}
              color={isCorrect ? "positive" : "negative"}
            />
          </Inline>
          <Text sans size={0} color="whisper">
            Deine Antwort
          </Text>
        </Stack>
      </Column>
      <Column width="80px">
        <Stack space="1" alignX={["left", "right"]}>
          <Text sans bold>
            {solution}
          </Text>
          <Text sans size={0} color="whisper">
            Lösung
          </Text>
        </Stack>
      </Column>
    </Columns>
  )
}

EvaluationRow.propTypes = {
  isCorrect: PropTypes.bool,
  title: PropTypes.node,
  answer: PropTypes.string,
  solution: PropTypes.string,
}

EvaluationRow.defaultProps = {
  answer: "-",
  solution: "-",
}

export default EvaluationRow
